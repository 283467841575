
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private messageService: MessageService, private toastService: ToastrService,) { }

    success(detail: string, message: string = 'Success !') {
        this.toastService.success(detail, message);

    }

    error(detail: string, message: string = "Error") {
        this.toastService.error(detail, message);
    }

    warn(detail: string, message: string = 'Warning!') {
        this.toastService.warning(detail, message);
    }

    info(detail: string, message: string = 'Info!') {
        this.toastService.info(detail, message);
    }
}